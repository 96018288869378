import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "https://mizube.oud.ai/api/users",
  baseURL: "http://127.0.0.1:5000/api/users",
  headers: {
    "Content-Type": "application/json",
  },
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can add common headers like Authorization here if needed
    // Example: config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle common errors (like 401 Unauthorized) here
    return Promise.reject(error);
  }
);

export default axiosInstance;
