import React from "react";
import PropTypes from "prop-types";
import { Route, useHistory } from "react-router-dom";
import { useAuth } from "auth-context/auth.context";
import SweetAlert from "react-bootstrap-sweetalert";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.token ? (
          <Component {...props} />
        ) : (
          <SweetAlert
            title="You must be signed in!"
            onCancel={() => history.push("/authentication/sign-in")}
            onConfirm={() => history.push("/authentication/sign-in")}
            confirmBtnCssClass={"px-5"}
          />
        )
      }
    />
  );
};

// ✅ Add PropTypes validation to fix the warning
ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired, // Ensures 'component' is a valid React component
};

export default ProtectedRoute;
