import PPEapi from "./api";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import CreditCard from "examples/Icons/CreditCard";
// import Cube from "examples/Icons/Cube";
// import SpaceShip from "examples/Icons/SpaceShip";
// import Cube from "assets/images/icon_ppe.png";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Document from "examples/Icons/Document";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
import Table from "examples/Table";
import styles from "layouts/tables/styles";
import image from "assets/images/icon_ppe.png";
import cal from "assets/images/cal.png";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import authorsTableData from "layouts/tables/data/authorsTableData";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
// import { useAuth } from "../../auth-context/auth.context";
function Tables() {
  const classes = styles();
  const { columns, rows } = authorsTableData;
  const [datalake, setDatalake] = useState([]);
  const [num, setNum] = useState(0);
  // const [interval, setInterval] = useState(0);
  const [mes, setMes] = useState("");
  const [down, setDown] = useState("");
  const [downp, setpDown] = useState("");
  const [vest, setVest] = useState("");
  const [pv, setPv] = useState("");
  const [ph, setPh] = useState("");
  const [pb, setPb] = useState("");
  const [pn, setPn] = useState("");
  const [helmet, setHelmet] = useState("");
  const [boots, setBoots] = useState("");
  const [filter, setFilter] = useState("30");
  const [info, setInfo] = useState("Default Last 30 days Report !");
  const [flag, setFlag] = useState("1");
  const [error, setError] = useState(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [previewImage, setPreviewImage] = useState(null); // Add this with other useState declarations
  const [count, setCount] = useState(0);
  const [visibleData, setVisibleData] = useState(15);
  const handleLoadMore = () => {
    setVisibleData((prev) => prev + 15);
  };

  const { chart, items } = {
    chart: {
      labels: ["NO-VEST", "NO-HELMET", "NO-BOOTS"],
      datasets: { label: "Counts", data: [vest, helmet, boots] },
    },
    items: [
      {
        icon: { color: "secondary", component: "settings" },
        label: "vest",
        progress: { content: String(pv) + " %", percentage: pv },
      },
      {
        icon: { color: "warning", component: "settings" },
        label: "helmet",
        progress: { content: String(ph) + " %", percentage: ph },
      },
      {
        icon: { color: "success", component: "settings" },
        label: "boots",
        progress: { content: String(pb) + " %", percentage: pb },
      },
      {
        icon: { color: "light", component: "settings" },
        label: "TOTAL",
        progress: { content: String(pn) + " no's", percentage: 100 },
      },
    ],
  };
  // let { user } = useAuth();

  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
    {
      setMes("");
    }
    {
      setError("");
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if ((num == 0) | (count > 0)) {
      PPEapi.get("/DemoDashboard", {
        params: {
          // username: user["username"],
          filter: filter,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      })
        .then(
          (res) => (
            console.log(res["data"]["no-vest"]),
            setDatalake(res["data"]["data"]),
            setVest(res["data"]["no-vest"]),
            setHelmet(res["data"]["no-helmet"]),
            setBoots(res["data"]["no-boots"]),
            setPv(res["data"]["pv"]),
            setPh(res["data"]["ph"]),
            setPb(res["data"]["pb"]),
            setPn(res["data"]["pn"]),
            setFlag(res["data"]["flag"])
          )
        )
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
    setCount(0);
    if (num > 10) {
      decrementNum();
    }
  });
  const link = () => {
    window.open("https://robomys3test.s3.ap-northeast-1.amazonaws.com/" + down, "_blank");
  };
  const linkp = () => {
    window.open("https://robomys3test.s3.ap-northeast-1.amazonaws.com/" + downp, "_blank");
  };
  const download = () => {
    setDown("");
    setpDown("");
    PPEapi.get("/downloaddash", {
      params: {
        // username: user["username"],
        filter: filter,
        startDate: startDate,
        endDate: endDate,
      },
    })
      .then((res) => (setDown(res.data["fname"]), setpDown(res.data["pname"])))
      .catch((err) => console.log(err));
  };

  const deletel_row_df = (val) => {
    var result = confirm(" Confirm To Delete?");
    console.log(val);
    if (result) {
      console.log(val);
      PPEapi.delete(`/DemoDashboard/${val}`).then((res) => {
        console.log(res);
      });
      decrementNum();
      {
        setMes("");
      }
      {
        setError("");
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={3} px={2}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={5}></Grid>
            <Grid item xs={12} sm={6} xl={2}></Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <SuiTypography variant="caption" textColor="secondary">
                {flag && info}
              </SuiTypography>
            </Grid>
            <Grid item xs={12} sm={6} xl={2}>
              {flag && (
                <select
                  name="dtcol"
                  id="dtcol"
                  style={{
                    width: "100%",
                    height: 35,
                    color: "#525557",
                    backgroundColor: "#e4e9eb",
                    border: "3px solid #e4e9eb",
                  }}
                  onChange={(event) => {
                    setFilter(event.target.value),
                      setInfo("Last " + event.target.value + " Days Report !"),
                      setNum((prev) => prev - 1);
                  }}
                >
                  <option value="365">--FILTER REPORT--</option>
                  <option value="365" key="full">
                    LAST YEAR
                  </option>
                  <option value="30" key="mon">
                    LAST MONTH
                  </option>
                  <option value="7" key="week">
                    LAST WEEKLY
                  </option>
                  <option value="1" key="day">
                    TODAY
                  </option>
                </select>
              )}
            </Grid>
          </Grid>
          <br></br>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3.6}>
              <MiniStatisticsCard
                title={{ text: "TOTAL VIOLATIONS" }}
                count={pn}
                percentage={{ color: "error", text: "" }}
                icon={{ color: "light", component: <Document size="20px" /> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={2.8}>
              <MiniStatisticsCard
                title={{ text: "NO VEST" }}
                count={vest}
                percentage={{ color: "error", text: pv + "%" }}
                icon={{
                  color: "secondary",
                  component: <CustomerSupport color="white" size="20px" />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={2.8}>
              <MiniStatisticsCard
                title={{ text: "NO HELMET" }}
                count={helmet}
                percentage={{ color: "error", text: ph + "%" }}
                icon={{
                  color: "warning",
                  component: <CustomerSupport color="white" size="20px" />,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={2.8}>
              <MiniStatisticsCard
                title={{ text: "NO BOOTS" }}
                count={boots}
                percentage={{ color: "error", text: pb + "%" }}
                icon={{
                  color: "primary",
                  component: <CustomerSupport color="white" size="20px" />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}></Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={2}>
              <SuiBox mb={3}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="light"
                    onClick={() => download()}
                    fullWidth
                  >
                    Create Report
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6} xl={0.35}>
              <SuiTypography variant="button" fontWeight="regular" textColor="text">
                From
              </SuiTypography>
            </Grid>
            <Grid item xs={12} sm={6} xl={0.4}>
              <SuiBox mr={2}>
                <SuiAvatar src={cal} alt={"name"} size="sm" variant="rounded" />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6} xl={1.7}>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date); // Store Date object directly
                  setInfo("");
                  setNum((prev) => prev - 1);
                  setDown(""); // Clear download links
                  setpDown(""); // Clear download links
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="Time"
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={0.2}>
              <SuiTypography variant="button" fontWeight="regular" textColor="text">
                &nbsp;&nbsp;To
              </SuiTypography>
            </Grid>

            <Grid item xs={12} sm={6} xl={0.4}>
              <SuiBox mr={2}>
                <SuiAvatar src={cal} alt={"name"} size="sm" variant="rounded" />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6} xl={1.4}>
              <SuiBox mb={1} ml={0.5}>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date); // Store Date object directly
                    setDown(""); // Clear download links
                    setpDown(""); // Clear download links
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="Time"
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6} xl={0.4}></Grid>
            <Grid item xs={12} sm={6} xl={2.5}>
              {down && (
                <SuiBox mb={3}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="success"
                      onClick={() => link()}
                      fullWidth
                    >
                      DOWNLOAD DATA .XLSX
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              )}
            </Grid>

            <Grid item xs={12} sm={6} xl={2.5}>
              {downp && (
                <SuiBox mb={3}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="success"
                      onClick={() => linkp()}
                      fullWidth
                    >
                      DOWNLOAD SUMMARY .PDF
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              )}
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={7}>
              <SuiBox mb={3}>
                <Card>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SuiTypography variant="h5" color="error" textGradient>
                      PPE-Detection
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox customClass={classes.tables_table}>
                    <Table columns={columns} rows={rows} />
                  </SuiBox>
                  {error && (
                    <SuiBox mt={2} mb={2} textAlign="right">
                      <h6 style={{ color: "red", textAlign: "right" }}>{error}</h6>
                    </SuiBox>
                  )}
                  {mes && (
                    <SuiBox mt={2} mb={2} textAlign="right">
                      <h6 style={{ color: "green", textAlign: "right" }}>{mes}</h6>
                    </SuiBox>
                  )}
                  <SuiBox customClass={classes.tables_table}>
                    <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
                      <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
                        <table className="MuiTable-root css-w793m5-MuiTable-root">
                          <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                            <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                TYPE
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                TIME STAMP
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                DETECT
                              </th>
                              {/* <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          DETECT
                        </th> */}
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                IMAGE
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                LOCATION
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                STATUS
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                DELETE
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {datalake.slice(0, visibleData).map((dlak, index) => {
                              return (
                                <tr
                                  className="MuiTableRow-root css-iw4ra7-MuiTableRow-root"
                                  key={index}
                                >
                                  <td>
                                    <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                      <SuiBox mr={2}>
                                        <SuiAvatar
                                          src={image}
                                          alt={"name"}
                                          size="sm"
                                          variant="rounded"
                                        />
                                      </SuiBox>
                                      <SuiBox display="flex" flexDirection="column">
                                        <SuiTypography variant="button" fontWeight="medium">
                                          {dlak.typ}
                                        </SuiTypography>
                                        <SuiTypography variant="caption" textColor="secondary">
                                          {dlak.fname}
                                        </SuiTypography>
                                      </SuiBox>
                                    </SuiBox>
                                  </td>

                                  <td>
                                    <SuiBox display="flex" flexDirection="column">
                                      <SuiTypography variant="caption" textColor="secondary">
                                        {dlak.time.slice(0, 19)}
                                      </SuiTypography>
                                    </SuiBox>
                                  </td>
                                  <td>
                                    <SuiBadge
                                      variant="gradient"
                                      badgeContent={dlak.detect}
                                      color={dlak.color}
                                      size="extra-small"
                                    />
                                  </td>
                                  {/* <td>
                              <SuiTypography
                                variant="caption"
                                textColor="secondary"
                                fontWeight="medium"
                              >
                                {" "}
                                {dlak.detect}
                              </SuiTypography>
                            </td> */}
                                  <td>
                                    <SuiBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                        overflow: "hidden",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setPreviewImage(dlak.img)} // Set modal image
                                    >
                                      <img
                                        src={dlak.img}
                                        alt="no image"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </SuiBox>
                                  </td>
                                  <td align="center">
                                    <SuiBox display="flex" flexDirection="column">
                                      <SuiTypography
                                        variant="caption"
                                        fontWeight="medium"
                                        textColor={dlak.ticket_color}
                                      >
                                        {dlak.cam_loc}
                                      </SuiTypography>
                                    </SuiBox>
                                  </td>
                                  <td align="center">
                                    <SuiBox display="flex" flexDirection="column">
                                      <SuiTypography
                                        variant="caption"
                                        fontWeight="medium"
                                        textColor={dlak.ticket_color}
                                      >
                                        {dlak.status}
                                      </SuiTypography>
                                    </SuiBox>
                                  </td>
                                  <td align="center">
                                    <Icon
                                      className="cursor-pointer"
                                      color="error"
                                      fontSize="medium"
                                      onClick={() => deletel_row_df(dlak.did)}
                                    >
                                      delete
                                    </Icon>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {visibleData < datalake.length && (
                          <SuiBox mt={2} textAlign="center">
                            <SuiTypography
                              variant="button"
                              fontWeight="medium"
                              onClick={handleLoadMore}
                              style={{
                                backgroundColor: "#d3d3d3", // Ash color
                                borderRadius: "20px", // Rounded edges for a pill shape
                                padding: "10px 20px", // Padding for better appearance
                                display: "inline-block", // Keeps it compact around the text
                                cursor: "pointer", // Hand cursor on hover
                                transition: "background-color 0.3s ease", // Smooth transition effect
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.backgroundColor = "#bcbcbc")
                              } // Slightly darker ash on hover
                              onMouseOut={(e) =>
                                (e.currentTarget.style.backgroundColor = "#d3d3d3")
                              } // Reset color on mouse out
                            >
                              Load More
                            </SuiTypography>
                          </SuiBox>
                        )}
                      </div>
                    </div>
                  </SuiBox>
                  {/* Modal for Image Preview */}
                  <Dialog open={!!previewImage} onClose={() => setPreviewImage(null)} maxWidth="lg">
                    <DialogContent>
                      <img
                        src={previewImage}
                        alt="Large Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          display: "block",
                          margin: "0 auto",
                        }}
                      />
                    </DialogContent>
                  </Dialog>
                </Card>
              </SuiBox>
            </Grid>
            <Grid item xs={12} md={6} xl={5}>
              <Grid item xs={12} lg={12}>
                <ReportsBarChart
                  title="PPE DETECTION"
                  description={
                    <>
                      (<strong>+ Warning</strong>)
                    </>
                  }
                  chart={chart}
                  items={items}
                />
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
