import { useState } from "react";
import { useHistory } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";

import { useAuth } from "../../../auth-context/auth.context";
import AuthApi from "../../../api/auth";

function SignIn() {
  const history = useHistory();
  const { setUser } = useAuth();

  const [rememberMe, setRememberMe] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [buttonText, setButtonText] = useState("Sign in");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const login = async (event) => {
    event.preventDefault();

    if (username === "") {
      setError("You must enter your email.");
      return;
    }
    if (password === "") {
      setError("You must enter your password.");
      return;
    }

    setButtonText("Signing in...");
    try {
      const response = await AuthApi.Login({ username, password });

      if (response.data && response.data.success === false) {
        setError(response.data.msg);
        setButtonText("Sign in");
        return;
      }

      // ✅ Call setProfile to handle login
      setProfile(response);
    } catch (err) {
      console.error(err);
      setButtonText("Sign in");

      if (err.response) {
        setError(err.response.data.msg);
      } else {
        setError("There has been an error.");
      }
    }
  };

  const setProfile = async (response) => {
    let user = { ...response.data.user };
    user.token = response.data.token;

    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));

    console.log("User logged in:", user.username);

    // ✅ Redirect based on user role
    if (user.is_special) {
      history.push("/demoobj"); // "special_user" goes to /demoobj
    } else {
      history.push("/dashboard"); // Other users go to /dashboard
    }
  };

  return (
    <CoverLayout
      title="OUD.AI MIZU"
      description="Enter your username and password to sign in"
      image={curved9}
    >
      <SuiBox component="form" role="form" onSubmit={login}>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            UserName
          </SuiTypography>
          <SuiInput
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
              setError(undefined);
            }}
            type="text"
            placeholder="username"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Password
          </SuiTypography>
          <SuiInput
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
              setError(undefined);
            }}
            type="password"
            placeholder="Password"
          />
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SuiTypography variant="button" fontWeight="regular">
            &nbsp;&nbsp;Remember me
          </SuiTypography>
        </SuiBox>
        {error && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ fontSize: ".8em", color: "red" }}>{error}</h6>
          </SuiBox>
        )}
        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" buttonColor="info" fullWidth type="submit">
            {buttonText}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default SignIn;
